import React, { useEffect, useRef, useState } from 'react'
import styles from './ChatHistoryList.module.css'
import { ChatHistory, Course } from '@/models'
import ChatHistoryItem from './ChatHistoryItem'

interface ChatHistoryListProps {
    course: Course
    expanded?: boolean
}

const ChatHistoryList: React.FC<ChatHistoryListProps> = ({ course, expanded }) => {
    const defaultMaxItems = 5
    const [maxItems, setMaxItems] = useState(defaultMaxItems)
    const listRef = useRef<HTMLUListElement>(null)
    const handleShowMore = () => {
        setMaxItems((prevMaxItems) => prevMaxItems + defaultMaxItems)
    }

    const handleShowLess = () => {
        // setMaxItems((prevMaxItems) => prevMaxItems - 5) -> decrease by 5
        setMaxItems(defaultMaxItems) // reset to 5
    }

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = 0
        }
    }, [course?.history])

    return (
        <div>
            <ul ref={listRef} className={`${styles.historyListContainer} ${expanded ? styles.expanded : ''}`}>
                {course?.history
                    ?.slice(0, maxItems)
                    .map((history: ChatHistory /*, index: number, arr: ChatHistory[]*/) => {
                        return (
                            <React.Fragment key={history.conversation_id}>
                                <ChatHistoryItem history={history} />
                                {/*index !== arr.length - 1 &&*/ <hr className={styles.horizontalLine} />}
                            </React.Fragment>
                        )
                    }) || (
                    <li
                        style={{
                            margin: '0.6rem',
                            listStyleType: 'none',
                            color: 'var(--sidebar-conversation-text-color)',
                        }}
                    >
                        No history found.
                    </li>
                )}
                {course?.history && course.history.length > maxItems ? (
                    <div className={styles.showMoreContainer} onClick={handleShowMore}>
                        Show More...
                    </div>
                ) : course?.history && course.history.length <= maxItems && course.history.length > defaultMaxItems ? (
                    <div className={styles.showMoreContainer} onClick={handleShowLess}>
                        Show Less...
                    </div>
                ) : null}
            </ul>
        </div>
    )
}

export default ChatHistoryList
