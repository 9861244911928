/**
 * Configuration object for Microsoft Authentication Library (MSAL).
 * This configuration is used to initialize the MSAL client instance for authenticating users via Azure Active Directory.
 *
 * The configuration includes:
 * - `auth`: Authentication related properties.
 *   - `clientId`: The Application (client) ID registered in the Azure portal.
 *   - `authority`: The URL of the authorization server. It can be the Microsoft sign-in endpoint or an issuer URL for the tenant.
 *   - `redirectUri`: The URI where the authentication response is sent and received by your app. It must match one of the redirect URIs registered in the Azure portal.
 *   - `OIDCOptions`: OpenID Connect specific options.
 *     - `defaultScopes`: Scopes that are used by default for all requests to the authorization endpoint.
 * - `cache`: Configuration related to the caching of tokens.
 *   - `cacheLocation`: The location in the browser where tokens are cached. Can be 'localStorage' or 'sessionStorage'.
 *
 * Note: The actual values for `clientId` and `authority` are imported from environment variables to ensure security and flexibility.
 */
const authConfig = {
    auth: {
        clientId: import.meta.env.VITE_CLIENTID,
        //clientId: '8adbdab2-f5da-48a2-a969-a2a47e758c2b',
        authority: import.meta.env.VITE_AUHTORITY,
        //authority: 'https://login.microsoftonline.com/3ddc4284-8377-4878-94bf-a35840ca61fe',
        redirectUri: '/',
        OIDCOptions: {
            defaultScopes: ['User.Read', 'Mail.Send'],
        },
    },
    cache: {
        cacheLocation: 'localStorage',
    },
}

export default authConfig
