import React, { useContext } from 'react'
import styles from './ChatHistoryItem.module.css'
import { DeleteRegular } from '@fluentui/react-icons'
import { deleteHistory } from '@/api'
import { ChatHistory } from '@/models'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { UserContext } from '../../contexts/UserContext'

interface ChatHistoryItemProps {
    history: ChatHistory
}

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({ history }) => {
    const { removeConversation, selectedConversation, setSelectedConversation, selectedCourse, setSelectedCourse } =
        useContext(SidebarContext)
    const userContext = useContext(UserContext)

    const handleDeleteClick = async () => {
        await deleteHistory(userContext.userId, userContext.idToken, history.conversation_id)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error deleting history: ' + response.statusText)
                }
                removeConversation(history.course, history.conversation_id)
                if (selectedConversation === history.conversation_id) {
                    setSelectedConversation(null)
                }
            })
            .catch((e) => {
                console.error(`Error deleting history: ${e}`)
            })
    }
    return (
        <li key={history.conversation_id} style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                className={`${styles.chatHistoryItemContainer} ${selectedConversation === history.conversation_id ? styles.selected : ''}`}
            >
                <div
                    className={styles.chatHistoryItemText}
                    onClick={() => {
                        setSelectedConversation(history.conversation_id)
                        if (history.course !== selectedCourse) {
                            setSelectedCourse(history.course)
                        }
                    }}
                >
                    {history.title}
                </div>
                <DeleteRegular
                    className={`${styles.chatHistoryDeleteIcon} ${selectedConversation === history.conversation_id ? styles.selected : ''}`}
                    onClick={handleDeleteClick}
                />
            </div>
        </li>
    )
}

export default ChatHistoryItem
