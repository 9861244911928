import LayoutDialog from '@/components/Layout/LayoutDialog'
import { useState, useEffect, useContext } from 'react'
import { loginRedirect, logout, refreshToken } from '@/auth/utils'
import { UserContext } from '@/contexts/UserContext'
import { disclaimerStatus } from '@/api'
import { Link } from 'react-router-dom'

/**
 * `LayoutDialogs` is a React component that manages disclaimer acceptance within the application:
 * It checks whether the user has accepted a disclaimer (a common requirement in applications that need explicit user consent on terms and conditions before use).
 * If the user has not accepted the disclaimer, a dialog is presented with the option to accept or reject it.
 * Accepting the disclaimer updates the user's status accordingly, while rejecting it redirects the user to a login or initial screen.
 *
 * The component makes use of the `UserContext` to access user-specific data such as user identifiers, which are essential for the disclaimer status check.
 *
 * Props:
 * - None. This component does not accept any props.
 *
 * State:
 * - `showDisclaimer`: Boolean state to control the visibility of the disclaimer acceptance dialog.
 *
 * Context:
 * - Uses `UserContext` to access the current user's user ID and ID token.
 *
 * Side Effects:
 * - A `useEffect` hook is used to initiate checks for disclaimer status on component mount and when relevant dependencies change.
 *
 */
export default function LayoutDialogs() {
    // we used to have a timer for the 2h token expiration popup, but it's we have removed it for now, and only care about the 24h token and let msal handle it.
    // const [isPopupOpen, setIsPopupOpen] = useState(false)
    // const [tokenRefreshed, setTokenRefreshed] = useState(false)
    // const tokenExpiry = useContext(UserContext).expiry
    // const TOKEN_EXPIRATION_POPUP_TIME = 120000 // 2 minutes in milliseconds (120000). The time it takes for the popup appear before token expiration.
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const { userId, idToken, setIdToken, setAccessToken, setExpiry } = useContext(UserContext)
    const disclaimerText = (
        <p className="flex flex-col gap-1 text-pretty">
            Seneca&apos;s MyTutor is designed to enhance your learning experience using a secure instance of OpenAI
            technology that protects Seneca content and student input. This tool is intended to supplement your
            education, not replace your primary course materials or instruction from your educators. While we strive to
            ensure the accuracy and relevance of Seneca&apos;s MyTutor responses, we do not guarantee the completeness
            or reliability of the information provided, and the output may not always reflect the most current course
            updates or individual instructor guidelines. For the most accurate and up-to-date information, students
            should always refer to official course materials or consult directly with their instructors. Additionally, 
            please do not share any private or confidential information in this chat.  All interactions will be recorded 
            and stored for audit purposes and will be shared with your instructor. For more
            information, please refer to
            <Link
                className="underline text-primary text-sm"
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.senecapolytechnic.ca/about/policies/generative-ai-policy.html"
            >
                Generative Artificial Intelligence (GenAI) Policy -Seneca Polytechnic, Toronto, Canada.
            </Link>
            By using MyTutor, you acknowledge and agree to these terms.
        </p>
    )
    // const logoutPopup = async () => {
    //     setIsPopupOpen(false)
    //     await logout()
    // }
    // const continuePopup = async () => {
    //     setIsPopupOpen(false)
    //     const refreshedToken = await refreshToken()
    //     if (refreshedToken) {
    //         setIdToken(refreshedToken.idToken)
    //         setAccessToken(refreshedToken.accessToken)
    //         setExpiry(refreshedToken.tokenExpiry)
    //         setTokenRefreshed(true)
    //     }
    // }

    // disclaimer dialog helpers
    const handleAcceptDisclaimer = async () => {
        try {
            const status = await disclaimerStatus(userId, idToken, 'POST')
            console.log('disclaimer status', status)
            setShowDisclaimer(!status)
        } catch (error) {
            console.error('Failed to accept disclaimer:', error)
        }
    }

    const handleRejectDisclaimer = () => {
        loginRedirect()
    }

    const checkDisclaimerStatus = async () => {
        if (!userId || !idToken) {
            return
        }
        try {
            const status = await disclaimerStatus(userId, idToken, 'GET')
            console.log('disclaimer status', status)
            setShowDisclaimer(!status)
        } catch (error) {
            // Handle any errors that occur during the fetch.
            console.error(error)
        }
    }

    useEffect(() => {
        checkDisclaimerStatus()
    }, [userId])
    // end of disclaimer dialog helpers

    // useEffect(() => {
    //     const calculateTokenExpiration = () => {
    //         const msalTokenExpirationTime = tokenExpiry?.getTime() ?? 0
    //         const currentTime = new Date().getTime()
    //         const timeUntilExpiration = msalTokenExpirationTime - currentTime - TOKEN_EXPIRATION_POPUP_TIME
    //         if (timeUntilExpiration > 0) {
    //             const timer = setTimeout(() => {
    //                 setIsPopupOpen(true)
    //             }, timeUntilExpiration)
    //             return () => clearTimeout(timer)
    //         }
    //     }

    //     calculateTokenExpiration()
    //     if (tokenRefreshed) {
    //         setTokenRefreshed(false)
    //     } else {
    //         const tokenExpirationWorker = new Worker(new URL('../../workers/tokenExpirationWorker.ts', import.meta.url))
    //         tokenExpirationWorker.postMessage({
    //             tokenExpiryTime: tokenExpiry?.getTime(),
    //             currentTime: new Date().getTime(),
    //             TOKEN_EXPIRATION_POPUP_TIME: TOKEN_EXPIRATION_POPUP_TIME,
    //         })

    //         tokenExpirationWorker.onmessage = function (event) {
    //             const { isPopupOpen } = event.data
    //             if (isPopupOpen) {
    //                 setIsPopupOpen(isPopupOpen)
    //             } else {
    //                 // The token has expired already so logout
    //                 loginRedirect()
    //             }
    //         }
    //     }
    // }, [tokenRefreshed])

    return (
        <>
            {showDisclaimer && (
                <LayoutDialog
                    continueDialog={handleAcceptDisclaimer}
                    closeDialog={handleRejectDisclaimer}
                    title="Disclaimer"
                    description={disclaimerText}
                    openButtonText="Accept"
                    closeButtonText="Reject"
                />
            )}
            {/* {isPopupOpen && (
                <LayoutDialog
                    countdownDuration={TOKEN_EXPIRATION_POPUP_TIME}
                    continueDialog={continuePopup}
                    closeDialog={logoutPopup}
                    title="Your session will expire soon."
                    description="You can choose to continue chatting or logout. Your session will expire in"
                    openButtonText="Continue"
                    closeButtonText="Logout"
                />
            )} */}
        </>
    )
}
