import { useEffect, useMemo, useState } from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { FontIcon, Stack, Text } from '@fluentui/react'
import { DismissRegular } from '@fluentui/react-icons'
import { AskResponse, Citation } from '@/models'
import { parseAnswer } from './AnswerParser'

import styles from './Answer.module.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import supersub from 'remark-supersub'

interface Props {
    answer: AskResponse
    // onCitationClicked: (citedDocument: Citation) => void
}

//export const Answer = ({ answer, onCitationClicked }: Props) => {
export const Answer = ({ answer }: Props) => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false)
    const filePathTruncationLimit = 50
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false)
    const [activeCitation, setActiveCitation] =
        useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>()
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer])
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen)

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded)
        toggleIsRefAccordionOpen()
    }

    const onCitationClicked = (citation: Citation, idx: number) => {
        setActiveCitation([
            citation.content,
            citation.id,
            citation.title ?? `Citation ${idx}`,
            citation.filepath ?? '',
            citation.url ?? '',
            citation.metadata ?? '',
        ])
        setIsCitationPanelOpen(true)
    }

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen)
    }, [isRefAccordionOpen])

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = ''

        if (citation.filepath && citation.chunk_id != null) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`
            } else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`
            }
        } else {
            citationFilename = `Citation ${index}`
        }
        return citationFilename
    }

    // Copy event listener disabled for now
    /*useEffect(() => {
        const handleCopy = () => {
            alert('Please consider where you paste this content.')
        }
        document.addEventListener('copy', handleCopy)
        return () => {
            document.removeEventListener('copy', handleCopy)
        }
    }, [])*/

    return (
        <Stack className={styles.answerContainer}>
            <Stack.Item grow style={{ flex: 'unset', width: '-webkit-fill-available' }}>
                <ReactMarkdown remarkPlugins={[remarkGfm, supersub]} className={styles.answerText}>
                    {parsedAnswer.markdownFormatText}
                </ReactMarkdown>
            </Stack.Item>
            <Stack horizontal className={styles.answerFooter} verticalAlign="start">
                <Stack.Item className={styles.answerDisclaimerContainer}>
                    <span className={`${styles.answerDisclaimer} ${styles.mobileAnswerDisclaimer}`}>
                        AI-generated content may be incorrect
                    </span>
                </Stack.Item>

                {!!parsedAnswer.citations.length && (
                    <Stack.Item aria-label="References">
                        <Stack style={{ width: '100%' }}>
                            <Stack horizontal horizontalAlign="start" verticalAlign="center">
                                <Text className={styles.accordionTitle} onClick={toggleIsRefAccordionOpen}>
                                    <span>
                                        {parsedAnswer.citations.length > 1
                                            ? parsedAnswer.citations.length + ' references'
                                            : '1 reference'}
                                    </span>
                                </Text>
                                <FontIcon
                                    className={styles.accordionIcon}
                                    onClick={handleChevronClick}
                                    iconName={chevronIsExpanded ? 'ChevronDown' : 'ChevronRight'}
                                />
                            </Stack>
                        </Stack>
                    </Stack.Item>
                )}
            </Stack>
            {chevronIsExpanded && (
                <div className={styles.citationsContainer}>
                    <div className={styles.citationsListContainer}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    key={idx}
                                    onClick={() => onCitationClicked(citation, idx)}
                                    className={styles.citationsListItem}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>
                            )
                        })}
                    </div>
                    {isCitationPanelOpen && activeCitation && (
                        <Stack.Item className={`${styles.citationPanelBackdrop}`}>
                            <Stack
                                horizontal
                                className={styles.citationPanelHeaderContainer}
                                horizontalAlign="space-between"
                                verticalAlign="center"
                            >
                                <span className={styles.citationPanelHeader}>{activeCitation[2]}</span>
                                <DismissRegular
                                    className={styles.citationPanelDismiss}
                                    onClick={() => setIsCitationPanelOpen(false)}
                                />
                            </Stack>
                            <div className={`${styles.citationPanelContent} ${styles.mobileCitationPanelContent}`}>
                                {activeCitation[0]}
                            </div>
                        </Stack.Item>
                    )}
                </div>
            )}
        </Stack>
    )
}
