import { useState, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { ChatHistory, Course } from '@/models'
import styles from './Layout.module.css'
import Navbar from '../../components/Navbar/Navbar'
import { Toaster as Sonner } from '@/components/ui/sonner'
import LayoutDialogs from '@/components/Layout/LayoutDialogs'

const Layout = () => {
    const [selectedCourse, setSelectedCourse] = useState<string | null>(null)
    const [selectedConversation, setSelectedConversation] = useState<string | null>(null)
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
    const [courses, setCourses] = useState<Course[] | null>(null)

    const SidebarContextValues = useMemo(
        () => ({
            selectedCourse,
            setSelectedCourse,
            selectedConversation,
            setSelectedConversation: setSelectedConversation,
            isCollapsed: isSidebarCollapsed,
            setIsCollapsed: setIsSidebarCollapsed,
            courses,
            setCourses,
            addConversation: (courseName: string, conversation: ChatHistory) => {
                if (!courses) {
                    return
                }
                const updatedCourses = courses.map((course) => {
                    if (course.name === courseName) {
                        const updatedHistory = course.history ? [...course.history, conversation] : [conversation]
                        updatedHistory.sort((a, b) => {
                            return b.updated_at.localeCompare(a.updated_at) // Sort in descending order
                        })
                        return { ...course, history: updatedHistory }
                    }
                    return course
                })
                setCourses(updatedCourses)
            },
            removeConversation: (courseName: string, conversationId: string) => {
                if (!courses) {
                    return
                }
                const updatedCourses = courses.map((course) => {
                    if (course.name === courseName) {
                        const updatedHistory = course.history?.filter(
                            (history) => history.conversation_id !== conversationId,
                        )
                        if (updatedHistory && updatedHistory.length === 0) {
                            return {
                                ...course,
                                history: null,
                            }
                        } else {
                            return {
                                ...course,
                                history: updatedHistory,
                            }
                        }
                    }
                    return course
                })
                setCourses(updatedCourses as Course[])
            },
        }),
        [
            selectedCourse,
            setSelectedCourse,
            selectedConversation,
            setSelectedConversation,
            isSidebarCollapsed,
            setIsSidebarCollapsed,
            courses,
            setCourses,
        ],
    )

    return (
        <SidebarContext.Provider value={SidebarContextValues}>
            <div className={`${styles.layout}`}>
                <Navbar />
                <LayoutDialogs />
            </div>
            <Sonner richColors closeButton visibleToasts={50} duration={5000} />
            <Outlet />
        </SidebarContext.Provider>
    )
}

export default Layout
