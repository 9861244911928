import React, { useEffect, useContext } from 'react'
import styles from './Sidebar.module.css'
import { getCourses, getHistory } from '@/api'
import { UserContext } from '../../contexts/UserContext'
import CoursesList from '../CoursesList/CoursesList'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { Course, ChatHistory } from '@/models'

import ContactSupportDialog from './ContactSupportDialog'
const Sidebar: React.FC = () => {
    const userContext = useContext(UserContext)
    const isCollapsed = useContext(SidebarContext).isCollapsed
    const sidebarContext = useContext(SidebarContext)

    useEffect(() => {
        const fetchData = async () => {
            userContext.checkExpiry()
            if (!userContext.idToken || !userContext.userId) {
                return
            }
            try {
                const coursesResponse = await getCourses(
                    userContext.idToken,
                    userContext.accessToken,
                    userContext.userId,
                )
                const coursesData = await coursesResponse.json()
                if (Object.keys(coursesData).length === 0) {
                    console.error('The user is not enrolled in any courses')
                    alert(
                        'You are not enrolled in any courses that are part of MyTutor. Please contact your system administrator to get enrolled in a course.',
                    )
                    sidebarContext.setCourses([])
                    return
                }

                try {
                    const historyResponse = await getHistory(userContext.userId, userContext.idToken)
                    const historyData = await historyResponse.json()
                    if (Object.keys(historyData).length === 0) {
                        console.log('No history found for the user')
                    }
                    const updatedCourses = coursesData.courses.map((course: Course) => {
                        if (historyData[course.name]) {
                            return {
                                ...course,
                                history: historyData[course.name].map((history: ChatHistory) => {
                                    return {
                                        conversation_id: history.conversation_id,
                                        course: course.name,
                                        title: history.title,
                                        updated_at: history.updated_at,
                                    }
                                }),
                            }
                        } else {
                            return course
                        }
                    })
                    sidebarContext.setCourses(updatedCourses)
                } catch (e) {
                    alert(
                        'An error occurred while fetching courses. Please try again later. If the problem persists, contact your system administrator.',
                    )
                    console.error(`Error in fetching history: ${e}`)
                    sidebarContext.setCourses(coursesData.courses)
                }
            } catch (e) {
                console.error(`Error in fetching courses: ${e}`)
            }
        }
        fetchData()
    }, [userContext.userId])
    let content
    if (!sidebarContext.courses || sidebarContext.courses.length === 0) {
        content = (
            <div className={styles.sidebarTitle}>
                <p>No courses found!</p>
            </div>
        )
    } else {
        content = (
            <>
                <CoursesList />
                <div className="flex justify-center items-center">
                    <ContactSupportDialog />
                </div>
            </>
        )
    }
    return (
        <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : styles.sidebar}`}>
            <div className={styles.sidebarTitle}>
                <span>Available Courses</span>
            </div>
            {content}
        </div>
    )
}

export default Sidebar
