import { useEffect, useState, StrictMode, useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { initializeIcons } from '@fluentui/react'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { initializeMsal, refreshToken, loginRedirect, msalInstance, USING_AUTH } from '@/auth/utils'

// Application Insights
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
const constr = import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTIONSTRING
const appInsights = new ApplicationInsights({
    config: {
        connectionString: constr,
        /* ...Other Configuration Options... */
    },
})
if (import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTIONSTRING) {
    appInsights.loadAppInsights()
    appInsights.trackPageView()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const telemetryInitializer = (envelope: any) => {
        envelope.tags['ai.cloud.role'] = 'CHATFRONTEND'
    }
    appInsights.addTelemetryInitializer(telemetryInitializer)
} else {
    console.log('Application Insights Not Starting')
}

import './index.css'

import { UserContext } from './contexts/UserContext'
import Layout from './pages/layout/Layout'
import NoPage from './pages/NoPage'
import Chat from './pages/chat/Chat'

// https://github.com/microsoft/fluentui/wiki/Using-icons#what-does-initializeicons-do
// used to initialize the icons and optimize the bundle size,
// must be called at the top level of the app before any components are rendered
initializeIcons()

export default function App() {
    // these are the user context values that will be used throughout the app
    const [userId, setUserId] = useState<string | null>(null)
    const [idToken, setIdToken] = useState<string | null>(null)
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const [tokenExpiry, setTokenExpiry] = useState<Date | null>(null)
    const [theme, setTheme] = useState<string>('light')

    const checkExpiry = async () => {
        if (tokenExpiry) {
            const currentTime = new Date().getTime()
            const timeUntilExpiration = tokenExpiry.getTime() - currentTime
            if (timeUntilExpiration <= 0) {
                // Attempt to refresh token if expired
                const refreshedToken = await refreshToken()
                if (refreshedToken) {
                    setIdToken(refreshedToken.idToken)
                    setAccessToken(refreshedToken.accessToken)
                    setTokenExpiry(refreshedToken.tokenExpiry)
                } else {
                    // if this doesn't work, use the logout function in auth/utils.ts
                    await loginRedirect()
                }
            }
        }
    }

    useEffect(() => {
        const initializeAuth = async () => {
            const authResult = await initializeMsal()
            if (authResult) {
                setUserId(authResult.userId)
                setIdToken(authResult.idToken)
                setAccessToken(authResult.accessToken)
                setTokenExpiry(authResult.tokenExpiry)
            }
        }
        initializeAuth()

        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
        if (prefersDarkMode) {
            setTheme('dark')
            document.documentElement.setAttribute('data-theme', 'dark')
        }
    }, [])

    useEffect(() => {
        checkExpiry()
    }, [tokenExpiry])

    const userContextValue = useMemo(
        () => ({
            userId,
            setUserId,
            idToken,
            setIdToken,
            accessToken,
            setAccessToken,
            expiry: tokenExpiry,
            setExpiry: setTokenExpiry,
            checkExpiry,
            loginRedirect,
            setTheme,
            theme,
        }),
        [userId, idToken, accessToken, tokenExpiry, theme],
    )
    return USING_AUTH ? (
        <MsalProvider instance={msalInstance}>
            <UserContext.Provider value={userContextValue}>
                <HashRouter>
                    <AuthenticatedTemplate>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Chat />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate></UnauthenticatedTemplate>
                </HashRouter>
            </UserContext.Provider>
        </MsalProvider>
    ) : (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Chat />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
        <App />
    </StrictMode>,
)
