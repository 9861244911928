type SupportEmailTemplateProps = Readonly<{
    content: string
    timestamp: string
    courseName: string
    conversationId: string
    conversationTitle: string
    userId: string
}>
export default function SupportEmailTemplate({
    content,
    timestamp,
    courseName,
    conversationId,
    conversationTitle,
    userId,
}: SupportEmailTemplateProps) {
    return (
        <div>
            <h1>Support Request</h1>
            <p>
                <strong>Sent From: </strong>
                {userId}
            </p>
            <p>
                <strong>Course Name: </strong>
                {courseName}
            </p>
            <p>
                <strong>Conversation ID: </strong>
                {conversationId}
            </p>
            <p>
                <strong>Conversation Title: </strong>
                {conversationTitle}
            </p>
            <p>
                <strong>Timestamp:</strong> {timestamp}
            </p>
            <p>
                <strong>Message: </strong>
                {content}
            </p>
        </div>
    )
}
