// External libraries
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useContext } from 'react'
import { renderToString } from 'react-dom/server'
import { toast } from 'sonner'

// Components
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import SupportEmailTemplate from '@/components/templates/SupportEmailTemplate'

// Contexts (Global utilities and constants)
import { UserContext } from '@/contexts/UserContext'
import { SidebarContext } from '@/contexts/SidebarContextProps'

// Services and API calls
import { sendEmail } from '@/api'

const formSchema = z.object({
    userId: z.string().email(),
    subject: z
        .string()
        .min(2, { message: 'Subject must be at least 2 characters long.' })
        .max(50, { message: 'Subject must be no more than 50 characters long.' }),
    message: z
        .string()
        .min(10, { message: 'Message must be at least 10 characters long.' })
        .max(500, { message: 'Message must be no more than 500 characters long.' }),
})

type SupportFormProps = Readonly<{
    closeDialog: () => void
}>

export default function SupportForm({ closeDialog }: SupportFormProps) {
    const { selectedCourse, selectedConversation, courses } = useContext(SidebarContext)
    const userContext = useContext(UserContext)

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            userId: userContext.userId ?? '',
            subject: '',
            message: '',
        },
    })

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        if (!selectedConversation || !selectedCourse) {
            return toast.info('Please select a course and conversation first.')
        }

        if (!userContext.accessToken) {
            return toast.error('An error occurred while sending the support request. Please try again.')
        }

        try {
            await sendEmail(userContext.accessToken, {
                subject: values.subject,
                body: {
                    contentType: 'html',
                    content: renderToString(
                        <SupportEmailTemplate
                            content={values.message}
                            timestamp={new Date().toLocaleString()}
                            conversationId={selectedConversation}
                            conversationTitle={
                                courses
                                    ?.find((course) => course.name === selectedCourse)
                                    ?.history.find((history) => history.conversation_id === selectedConversation)
                                    ?.title ?? 'ERROR: failed to find conversation title'
                            }
                            courseName={selectedCourse}
                            userId={values.userId}
                        />,
                    ),
                },
                toRecipients: [{ emailAddress: { address: import.meta.env.VITE_RECIPIENT_EMAIL } }],
            })

            toast.success('Support request sent successfully!')
        } catch (error) {
            toast.error('An error occurred while sending the support request. Please try again.')
        }
        closeDialog()
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="subject"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel htmlFor={field.name}>Subject</FormLabel>
                            <Input id={field.name} {...field} />
                            <FormDescription>Enter a subject for your message</FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="message"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel htmlFor={field.name}>Message</FormLabel>
                            <Textarea className="!bg-white !text-foreground" id={field.name} {...field} />
                            <FormDescription>Tell us what went wrong</FormDescription>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <Button type="submit">Submit</Button>
            </form>
        </Form>
    )
}
