import React from 'react'

interface UserContextProps {
    userId: string | null
    idToken: string | null
    accessToken: string | null
    expiry: Date | null
    theme: string | null
    setUserId: (value: string) => void
    setIdToken: (value: string) => void
    setAccessToken: (value: string) => void
    setExpiry: (value: Date) => void
    checkExpiry: () => void
    setTheme: (value: string) => void
}

export const UserContext = React.createContext<UserContextProps>({
    userId: null,
    idToken: null,
    accessToken: null,
    expiry: null,
    theme: null,
    setUserId: () => {},
    setIdToken: () => {},
    setAccessToken: () => {},
    setExpiry: () => {},
    checkExpiry: () => {},
    setTheme: () => {},
})
