import { /*ChatResponse,*/ ConversationRequest } from '@/models'
import { EmailMessage } from '@/types/EmailMessage'

export async function conversationApi(
    options: ConversationRequest,
    abortSignal: AbortSignal,
    idToken: string,
    userId: string,
): Promise<Response> {
    const response = await fetch(import.meta.env.VITE_API_URL + '/api/conversation/azure_byod', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
            user_id: userId,
        },
        body: JSON.stringify({
            messages: options.messages,
        }),
        signal: abortSignal,
    })

    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }

    return response
}

// **** This route is disabled in the current implementation ****
/*export async function customConversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch('/api/conversation/custom', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            messages: options.messages,
            conversation_id: options.id,
        }),
        signal: abortSignal,
    })
 
    return response
}*/

export async function getCourses(
    idToken: string | null,
    accessToken: string | null,
    userId: string | null,
): Promise<Response> {
    if (userId === null || idToken === null || accessToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + `/api/data/courses`, {
        method: 'GET',
        headers: {
            Authorization: idToken,
            'User-Id': userId,
            'Access-Token': accessToken,
        },
    })

    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function generateHistory(
    options: ConversationRequest,
    idToken: string | null,
    userId: string | null,
): Promise<Response> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + '/api/history/generate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
        },
        body: JSON.stringify({
            user_id: userId,
            conversation_id: options.id,
            course: options.messages[options.messages.length - 1].course,
            message: options.messages[0].content,
        }),
    })

    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function updateHistory(
    userId: string | null,
    idToken: string | null,
    messageId: string,
    conversationId: string,
    role: string,
    content: string,
): Promise<Response> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + '/api/history/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
        },
        body: JSON.stringify({
            user_id: userId,
            message_id: messageId,
            conversation_id: conversationId,
            role: role,
            content: content,
        }),
    })
    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function getHistory(userId: string | null, idToken: string | null): Promise<Response> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + `/api/history/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
            'User-Id': userId,
        },
    })
    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function readHistory(
    userId: string | null,
    idToken: string | null,
    conversationId: string,
): Promise<Response> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + `/api/history/read`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
            'User-Id': userId,
            'Conversation-Id': conversationId,
        },
    })
    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function deleteHistory(
    userId: string | null,
    idToken: string | null,
    conversationId: string,
): Promise<Response> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }
    const response = await fetch(import.meta.env.VITE_API_URL + `/api/history/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: idToken,
            'User-Id': userId,
            'Conversation-Id': conversationId,
        },
    })
    if (!response.ok) {
        await response.json().then((data) => {
            throw new Error(data.error)
        })
    }
    return response
}

export async function disclaimerStatus(
    userId: string | null,
    idToken: string | null,
    method: string,
): Promise<boolean> {
    if (userId === null || idToken === null) {
        throw new Error('User not logged in')
    }

    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/disclaimer`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: idToken,
                'User-Id': userId,
            },
        })
        if (!response.ok) {
            const errorText = await response.text()
            throw new Error(`Server responded with a ${response.status}: ${errorText}`)
        }
        const result = await response.json()
        return result.disclaimerAccepted
    } catch (error) {
        console.error('Error checking disclaimer status:', error)
        return false // Assume not accepted on error
    }
}

/**
 * Sends an email using the Microsoft Graph API.
 *
 * @param {string} accessToken - The access token for authentication with Microsoft Graph API.
 * @param {EmailMessage} message - The email message object containing details like recipient, subject, body, etc.
 * @returns {Promise<Response>} The response from the Microsoft Graph API.
 * @throws {Error} Throws an error if the HTTP request fails.
 */
export async function sendEmail(accessToken: string, message: EmailMessage) {
    const url = 'https://graph.microsoft.com/v1.0/me/sendMail'

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
    })

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
    }

    return response
}
