import { useRef, useState, useEffect, useContext, Fragment } from 'react'
import { Stack } from '@fluentui/react'
import { SquareRegular } from '@fluentui/react-icons'
// import { SpeechConfig, AudioConfig, SpeechRecognizer, ResultReason } from 'microsoft-cognitiveservices-speech-sdk'
import { v4 as uuidv4 } from 'uuid'
import styles from './Chat.module.css'
import SenecaBlack from '../../assets/Seneca-black.png'
import SenecaWhite from '../../assets/Seneca.png'
import {
    conversationApi,
    /*customConversationApi, // Not used in the current implementation*/
    generateHistory,
    updateHistory,
    readHistory,
} from '@/api'

import { ChatMessage, ConversationRequest, ToolMessageContent, ChatResponse, Course, ChatHistory } from '@/models'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Answer } from '../../components/Answer'
import { QuestionInput } from '../../components/QuestionInput'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { UserContext } from '../../contexts/UserContext'
import { Button } from '@/components/ui/button'

const Chat = () => {
    const userContext = useContext(UserContext)
    const { selectedCourse, selectedConversation, setSelectedConversation, addConversation, courses, setCourses } =
        useContext(SidebarContext)
    const lastQuestionRef = useRef<string>('')
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false)
    const [answers, setAnswers] = useState<ChatMessage[]>([])
    const abortFuncs = useRef([] as AbortController[])
    const [conversationId, setConversationId] = useState<string>(uuidv4())
    // const [userMessage, setUserMessage] = useState('')
    const [recognizedText, setRecognizedText] = useState<string>('')
    // const [isRecognizing, setIsRecognizing] = useState(false)
    // const [isListening, setIsListening] = useState(false)
    // const recognizerRef = useRef<SpeechRecognizer | null>(null)
    // const [subscriptionKey, setSubscriptionKey] = useState<string>('')
    // const [serviceRegion, setServiceRegion] = useState<string>('')
    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question

        setIsLoading(true)
        setShowLoadingMessage(true)
        const abortController = new AbortController()
        abortFuncs.current.unshift(abortController)

        const userMessage: ChatMessage = {
            id: uuidv4(),
            role: 'user',
            content: recognizedText || question,
            course: selectedCourse ?? 'none',
        }

        const request: ConversationRequest = {
            id: conversationId,
            messages: [...answers, userMessage],
        }
        let result = {} as ChatResponse
        try {
            // const response = await customConversationApi(
            if (!userContext.idToken || !userContext.userId) {
                throw new Error('User not logged in')
            }
            userContext.checkExpiry()

            // Strip the tool messages (citations) off subsequent requests
            // This keeps the request size (and therefore input token count) down
            request.messages.forEach((message) => {
                if (message.role == 'tool') {
                    request.messages.splice(request.messages.indexOf(message), 1)
                }
            })

            const response = await conversationApi(
                request,
                abortController.signal,
                userContext.idToken,
                userContext.userId,
            )
            if (response?.body) {
                const reader = response.body.getReader()
                let runningText = ''
                while (true) {
                    const { done, value } = await reader.read()
                    if (done) break

                    const text = new TextDecoder('utf-8').decode(value)
                    const objects = text.split('\n')
                    objects.forEach((obj) => {
                        try {
                            runningText += obj
                            result = JSON.parse(runningText)
                            setShowLoadingMessage(false)
                            if (result.error) {
                                setAnswers([
                                    ...answers,
                                    userMessage,
                                    {
                                        id: uuidv4(),
                                        role: 'error',
                                        content: result.error,
                                        course: selectedCourse ?? 'none',
                                    },
                                ])
                            } else {
                                setAnswers([...answers, userMessage, ...result.choices[0].messages])
                            }
                            runningText = ''
                        } catch {}
                    })
                }
                setAnswers([...answers, userMessage, ...result.choices[0].messages])
                if (userMessage.course !== 'none' && userMessage.course !== null) {
                    let newChat = false
                    generateHistory(request, userContext.idToken, userContext.userId)
                        .then(async (response) => {
                            if (!response.ok) {
                                await response.json().then((body) => {
                                    alert(body.error)
                                })
                            }
                            await response.json().then((body) => {
                                if (body.success) {
                                    if (body.title == 'Untitled Conversation') {
                                        alert(
                                            'There was an error generating a title for the conversation. You can continue the conversation, but if the issue persists, please contact your system administrator.',
                                        )
                                    }
                                    const newHistory: ChatHistory = {
                                        course: userMessage.course,
                                        conversation_id: body.conversation_id,
                                        title: body.title,
                                        messages: request.messages,
                                        updated_at: body.updated_at,
                                    }
                                    addConversation(userMessage.course, newHistory)
                                    newChat = true
                                    document.title = body.title
                                }
                            })
                            // update the history with the user message
                            updateHistory(
                                userContext.userId,
                                userContext.idToken,
                                userMessage.id,
                                request.id,
                                userMessage.role,
                                userMessage.content,
                            )
                                .then(async (response) => {
                                    if (!response.ok) {
                                        throw new Error(response.statusText)
                                    }
                                    // update the history with the assistant message
                                    updateHistory(
                                        userContext.userId,
                                        userContext.idToken,
                                        result.id,
                                        request.id,
                                        result.choices[0].messages[1].role,
                                        result.choices[0].messages[1].content,
                                    )
                                        .then(async (response): Promise<void> => {
                                            if (!response.ok) {
                                                throw new Error(response.statusText)
                                            }
                                            await response.json().then((body) => {
                                                if (body.success && courses && !newChat) {
                                                    const updatedCourses = courses.map((course: Course) => {
                                                        if (course.name === userMessage.course) {
                                                            const updatedHistory = course.history?.map((history) => {
                                                                if (history.conversation_id === body.conversation_id) {
                                                                    return {
                                                                        ...history,
                                                                        updated_at: body.updated_at,
                                                                    }
                                                                }
                                                                return history
                                                            })
                                                            updatedHistory.sort((a, b) => {
                                                                return b.updated_at.localeCompare(a.updated_at) // Sort in descending order
                                                            })
                                                            return {
                                                                ...course,
                                                                history: updatedHistory,
                                                            }
                                                        }
                                                        return course
                                                    })
                                                    setCourses(updatedCourses)
                                                }
                                            })
                                        })
                                        .catch((e): void => {
                                            console.error(e)
                                            setAnswers([
                                                userMessage,
                                                ...answers,
                                                {
                                                    id: uuidv4(),
                                                    role: 'error',
                                                    content: e.message,
                                                    course: selectedCourse ?? 'none',
                                                },
                                            ])
                                        })
                                })
                                .catch((e): void => {
                                    console.error(e)
                                    setAnswers([
                                        userMessage,
                                        ...answers,
                                        {
                                            id: uuidv4(),
                                            role: 'error',
                                            content: e.message,
                                            course: selectedCourse ?? 'none',
                                        },
                                    ])
                                })
                        })
                        .catch((e) => {
                            setAnswers([
                                userMessage,
                                ...answers,
                                {
                                    id: uuidv4(),
                                    role: 'error',
                                    content: e.message,
                                    course: selectedCourse ?? 'none',
                                },
                            ])
                        })
                }
            }
        } catch (e) {
            if (!abortController.signal.aborted) {
                console.error(e)
            }
            setAnswers([...answers, userMessage])
        } finally {
            setIsLoading(false)
            setShowLoadingMessage(false)
            abortFuncs.current = abortFuncs.current.filter((a) => a !== abortController)
        }

        return abortController.abort()
    }

    useEffect(() => {
        const handleConversationSelectionChange = () => {
            userContext.checkExpiry()
            if (!selectedConversation) {
                clearChat()
            } else if (selectedConversation !== conversationId) {
                changeConversationHistory()
                setConversationId(selectedConversation)
            }
        }

        handleConversationSelectionChange()
    }, [selectedConversation])

    useEffect(() => {
        const handlePageTitleChange = () => {
            const currentCourseHistory = courses?.find((course) => course.name === selectedCourse)?.history
            document.title =
                currentCourseHistory?.find(
                    (conversation_id) => selectedConversation === conversation_id.conversation_id,
                )?.title ?? `MyTutor${selectedCourse ? ` - ${selectedCourse}` : ''}`
        }

        handlePageTitleChange()
    }, [selectedConversation, selectedCourse])

    // NOT USED IN THE CURRENT IMPLEMENTATION
    /*useEffect(() => {
        async function fetchServerConfig() {
            try {
                const response = await fetch('/api/config')
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const data = await response.json()
                const fetchedSubscriptionKey = data.azureSpeechKey
                const fetchedServiceRegion = data.azureSpeechRegion

                setSubscriptionKey(fetchedSubscriptionKey)
                setServiceRegion(fetchedServiceRegion)
            } catch (error) {
                console.error('Error fetching server configuration:', error)
            }
        }

        fetchServerConfig()
    }, [])*/

    // NOT USED IN THE CURRENT IMPLEMENTATION
    /*const startSpeechRecognition = (subscriptionKey: string, serviceRegion: string) => {
        if (!isRecognizing) {
            setIsRecognizing(true)

            if (!subscriptionKey || !serviceRegion) {
                console.error('Azure Speech subscription key or region is not defined.')
            } else {
                const speechConfig = SpeechConfig.fromSubscription(subscriptionKey, serviceRegion)
                const audioConfig = AudioConfig.fromDefaultMicrophoneInput()
                const recognizer = new SpeechRecognizer(speechConfig, audioConfig)
                recognizerRef.current = recognizer // Store the recognizer in the ref

                recognizerRef.current.recognized = (s, e) => {
                    if (e.result.reason === ResultReason.RecognizedSpeech) {
                        const recognized = e.result.text
                        // console.log("Recognized:", recognized);
                        setUserMessage(recognized)
                        setRecognizedText(recognized)
                    }
                }

                recognizerRef.current.startContinuousRecognitionAsync(() => {
                    setIsRecognizing(true)
                    // console.log("Speech recognition started.");
                    setIsListening(true)
                })
            }
        }
    }*/

    // NOT USED IN THE CURRENT IMPLEMENTATION
    /*const stopSpeechRecognition = () => {
        if (isRecognizing) {
            // console.log("Stopping continuous recognition...");
            if (recognizerRef.current) {
                recognizerRef.current.stopContinuousRecognitionAsync(() => {
                    // console.log("Speech recognition stopped.");
                    recognizerRef.current?.close()
                })
            }
            setIsRecognizing(false)
            setRecognizedText('')
            setIsListening(false)
        }
    }*/

    // NOT USED IN THE CURRENT IMPLEMENTATION
    /*const onMicrophoneClick = () => {
        if (!isRecognizing) {
            // console.log("Starting speech recognition...");
            startSpeechRecognition(subscriptionKey, serviceRegion)
        } else {
            // console.log("Stopping speech recognition...");
            stopSpeechRecognition()
            setRecognizedText(userMessage)
        }
    }*/

    const clearChat = () => {
        if (!isLoading) {
            lastQuestionRef.current = ''
            setAnswers([])
            const newConversationId = uuidv4()
            setSelectedConversation(newConversationId)
            setConversationId(newConversationId)
        }
    }

    const stopGenerating = () => {
        abortFuncs.current.forEach((a) => a.abort())
        setShowLoadingMessage(false)
        setIsLoading(false)
    }

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' }), [showLoadingMessage, answers])

    const changeConversationHistory = async () => {
        await readHistory(userContext.userId, userContext.idToken, selectedConversation ?? '')
            .then(async (response) => {
                if (!response.ok) {
                    console.error('Error reading history:', response.statusText)
                }
                await response.json().then(async (data) => {
                    if (data.length > 1) {
                        setAnswers(data)
                        lastQuestionRef.current = data[data.length - 2].content
                    }
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }
    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message.role === 'tool') {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent
                return toolMessage.citations
            } catch {
                return []
            }
        }
        return []
    }
    return (
        <div className={styles.mainContainer}>
            <Sidebar />
            <Stack horizontal className={styles.chatRoot} style={{ width: '80%' }}>
                <div className={`${styles.chatContainer} ${styles.MobileChatContainer}`}>
                    {!lastQuestionRef.current ? (
                        <Stack className={styles.chatEmptyState}>
                            {userContext.theme === 'dark' ? (
                                <img
                                    src={SenecaWhite}
                                    className={styles.chatIcon}
                                    aria-hidden="true"
                                    alt="seneca logo"
                                />
                            ) : (
                                <img
                                    src={SenecaBlack}
                                    className={styles.chatIcon}
                                    aria-hidden="true"
                                    alt="seneca logo"
                                />
                            )}
                            {/*? why we have an empty title ?*/}
                            <h1 className={styles.chatEmptyStateTitle}></h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>
                                {selectedCourse === null
                                    ? 'Please select a course from the list on the left'
                                    : 'Type your questions about ' + selectedCourse + ' below'}
                            </h2>
                        </Stack>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <Fragment key={answer.id ?? uuidv4()}>
                                    {answer.role === 'user' ? (
                                        <div className={styles.chatMessageUser}>
                                            <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                        </div>
                                    ) : answer.role === 'assistant' || answer.role === 'error' ? (
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                answer={{
                                                    answer:
                                                        answer.role === 'assistant'
                                                            ? answer.content
                                                            : 'Sorry, an error occurred. Try refreshing the conversation or waiting a few minutes. If the issue persists, contact your system administrator. Error: ' +
                                                              answer.content,
                                                    citations:
                                                        answer.role === 'assistant'
                                                            ? parseCitationFromMessage(answers[index - 1])
                                                            : [],
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </Fragment>
                            ))}
                            {showLoadingMessage && (
                                <>
                                    <div className={styles.chatMessageUser}>
                                        <div className={styles.chatMessageUserMessage}>{lastQuestionRef.current}</div>
                                    </div>
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            answer={{
                                                answer: 'Generating answer...',
                                                citations: [],
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                    {/* <div>
                        {isRecognizing && !isListening && <p>Please wait...</p>} {isListening && <p>Listening...</p>}{' '}
                    </div> */}
                    {isLoading && (
                        <div
                            className={styles.stopGeneratingContainer}
                            role="button"
                            aria-label="Stop generating"
                            onClick={stopGenerating}
                            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ' ? stopGenerating() : null)}
                        >
                            <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                            <span className={styles.stopGeneratingText} aria-hidden="true">
                                Stop generating
                            </span>
                        </div>
                    )}
                    <div className={styles.chatInput}>
                        <Button size="lg" className="px-4" onClick={clearChat} disabled={isLoading}>
                            New Conversation
                        </Button>

                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question..."
                            disabled={isLoading}
                            onSend={(question) => makeApiRequest(question)}
                            recognizedText={recognizedText}
                            // onMicrophoneClick={onMicrophoneClick}
                            // onStopClick={stopSpeechRecognition}
                            // isListening={isListening}
                            // isRecognizing={isRecognizing}
                            onMicrophoneClick={() => {}}
                            onStopClick={() => {}}
                            isListening={false}
                            isRecognizing={false}
                            setRecognizedText={setRecognizedText}
                        />
                    </div>
                </div>
            </Stack>
        </div>
    )
}

export default Chat
