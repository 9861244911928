import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
} from '@/components/ui/dialog'

import { Button } from '@/components/ui/button'
import { useState, useEffect } from 'react'

type LayoutDialogProps = {
    title: string
    description: string | JSX.Element
    openButtonText: string
    closeButtonText: string
    countdownDuration?: number
    continueDialog: () => void
    closeDialog: () => void
}

/**
 * This is a custom dialog component that cannot be closed by the user
 * except by accepting or rejecting the terms presented within.
 * It optionally supports a countdown timer that triggers the logoutPopup function
 * when it reaches zero, indicating session expiration.
 * It can also be user to force the user to accept or reject terms before continuing.
 */
export default function LayoutDialog({
    title,
    description,
    openButtonText,
    closeButtonText,
    countdownDuration,
    continueDialog,
    closeDialog,
}: Readonly<LayoutDialogProps>) {
    // Initialize timer with null to indicate no countdown should start by default
    const [timer, setTimer] = useState<number | null>(null)
    // Start the countdown only if countdownDuration is provided
    useEffect(() => {
        if (countdownDuration && countdownDuration > 0) {
            setTimer(countdownDuration / 1000) // Convert ms to seconds
            const countdownInterval = setInterval(() => {
                setTimer((prevTimer) => (prevTimer ? prevTimer - 1 : 0))
            }, 1000)

            return () => clearInterval(countdownInterval)
        }
    }, [countdownDuration])

    // Effect to handle logout when timer reaches 0
    useEffect(() => {
        if (timer === 0) {
            closeDialog()
        }
    }, [timer, closeDialog])

    return (
        <Dialog defaultOpen>
            <DialogContent
                disableClose
                onInteractOutside={(e) => e.preventDefault()}
                onEscapeKeyDown={(e) => e.preventDefault()}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>
                <DialogDescription asChild={typeof description !== 'string'} className="text-balance">
                    <div>
                        {description} {!!timer && <p>Your session will expire in {timer} seconds.</p>}
                    </div>
                </DialogDescription>
                <DialogFooter className="flex gap-4 sm:gap-0">
                    <Button variant="outline" onClick={closeDialog} aria-label="Close dialog">
                        {closeButtonText}
                    </Button>
                    <Button onClick={continueDialog} aria-label="Continue with dialog" autoFocus>
                        {openButtonText}
                    </Button>
                </DialogFooter>
                <DialogClose disabled />
            </DialogContent>
        </Dialog>
    )
}
