import { useState, useContext, useEffect } from 'react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { PersonSupportRegular } from '@fluentui/react-icons'
import SupportForm from '@/components/Sidebar/SupportForm'
import { SidebarContext } from '@/contexts/SidebarContextProps'
import { toast } from 'sonner'

export default function ContactSupportDialog() {
    const [isOpen, setIsOpen] = useState(false)
    const [courseHasConversations, setCourseHasConversations] = useState(false)
    const { selectedCourse, selectedConversation, courses } = useContext(SidebarContext)

    const handleProceedClick = () => {
        const course = courses?.find((course) => course.name === selectedCourse)
        const conversationExists = course?.history?.some(
            (conversation) => conversation.conversation_id === selectedConversation,
        )
        setCourseHasConversations(!!conversationExists)

        if (!conversationExists) {
            toast.info('Please select a course and a conversation before proceeding.')
        } else {
            setIsOpen(true)
        }
    }

    const closeDialog = () => {
        setIsOpen(false)
    }

    return (
        <Dialog open={isOpen && courseHasConversations} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button
                    onClick={handleProceedClick}
                    size="lg"
                    variant="outline"
                    className="flex flex-row h-fit w-fit p-1.5 mb-2 text-balance lg:text-nowrap bg-transparent hover:bg-transparent hover:border-2"
                >
                    <PersonSupportRegular className="text-3xl" />
                    Report Conversation Issue
                </Button>
            </DialogTrigger>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Contact Support</DialogTitle>
                    <DialogDescription asChild>
                        <SupportForm closeDialog={closeDialog} />
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}
