import { createContext } from 'react'
import { ChatHistory, Course } from '@/models'

interface SidebarContextProps {
    selectedCourse: string | null
    setSelectedCourse: (value: string) => void
    selectedConversation: string | null
    setSelectedConversation: (value: string | null) => void
    isCollapsed: boolean
    setIsCollapsed: (value: boolean) => void
    courses: Course[] | null
    setCourses: (value: Course[]) => void
    addConversation: (courseName: string, conversation: ChatHistory) => void
    removeConversation: (courseName: string, conversationId: string) => void
}

export const SidebarContext = createContext<SidebarContextProps>({
    selectedCourse: null,
    setSelectedCourse: () => {},
    selectedConversation: null,
    setSelectedConversation: () => {},
    isCollapsed: false,
    setIsCollapsed: () => {},
    courses: null,
    setCourses: () => {},
    addConversation: () => {},
    removeConversation: () => {},
})
