// src/auth/utils.ts
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'
import authConfig from './authConfig'

export const USING_AUTH = true

export const msalInstance = new PublicClientApplication(authConfig)

/**
 * Initializes the MSAL (Microsoft Authentication Library) instance and attempts to authenticate the user silently.
 *
 * The process involves the following steps:
 * 1. Initializes the MSAL instance if `USING_AUTH` is true. This is necessary to prevent multiple instances
 *    from being created on re-renders.
 * 2. Handles any authentication responses from redirect operations that might have occurred on page load.
 * 3. Checks if any user accounts are already present in the MSAL instance. If so, it attempts to acquire an
 *    access token silently for the first account found.
 * 4. If silent token acquisition fails due to a need for user interaction (e.g., login prompt or consent),
 *    it falls back to acquiring a token through a redirect operation.
 * 5. If no accounts are detected, it initiates a login redirect to prompt the user for authentication.
 *
 * On successful silent token acquisition, it returns an object containing the user's ID (email), ID token,
 * access token, and the token's expiry time.
 *
 * Note: This function assumes that `USING_AUTH` is a global constant indicating whether authentication is
 * required for the application.
 *
 * @returns {Promise<{userId: string, idToken: string, accessToken: string, tokenExpiry: Date} | undefined>}
 * A promise that resolves to an object containing authentication details (userId, idToken, accessToken, tokenExpiry)
 * if authentication is successful and `USING_AUTH` is true. If `USING_AUTH` is false or authentication fails,
 * the promise resolves to undefined.
 */
export const initializeMsal = async () => {
    if (USING_AUTH) {
        // MSAL instance needs to be instantiated outside to prevent multiple re-instantiations on re-renders.
        await msalInstance.initialize()
        await msalInstance.handleRedirectPromise()
        const accounts = msalInstance.getAllAccounts()
        if (accounts.length > 0) {
            const userId = accounts[0]?.idTokenClaims?.email as string
            // Account detected, try to acquire a token silently with given request
            const silentRequest = {
                scopes: ['User.Read'],
                account: accounts[0],
            }
            try {
                const token = await msalInstance.acquireTokenSilent(silentRequest)
                return {
                    userId,
                    idToken: token.idToken,
                    accessToken: token.accessToken,
                    tokenExpiry: new Date((token.idTokenClaims as { exp: number }).exp * 1000),
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    // If silent token acquisition fails, interaction is needed by TokenRedirect.
                    try {
                        await msalInstance.acquireTokenRedirect(silentRequest)
                    } catch (error) {
                        console.error('Token acquisition failed:', error)
                    }
                } else {
                    console.error('Non-interactive error occurred:', error)
                }
            }
        } else {
            try {
                await msalInstance.loginRedirect({ scopes: ['User.Read'] })
            } catch (error) {
                console.error('Error during login:', error)
            }
        }
    }
}

export const refreshToken = async () => {
    const accounts = msalInstance.getAllAccounts()
    try {
        const token = await msalInstance.acquireTokenSilent({
            scopes: ['User.Read'],
            account: accounts[0],
            forceRefresh: true,
        })
        return {
            idToken: token.idToken,
            accessToken: token.accessToken,
            tokenExpiry: new Date((token.idTokenClaims as { exp: number }).exp * 1000),
        }
    } catch (error) {
        console.error('Error during token refresh:', error)
        if (error instanceof InteractionRequiredAuthError) {
            //
            await loginRedirect()
        }
    }
}

/**
 * Clears the cache first, then redirects to the logout page.
 * Note that there is no way to programmatically invalidate the token on the server side.
 * This is a client-side operation only.
 */
export const logout = async () => {
    try {
        await msalInstance.logoutRedirect()
    } catch (error) {
        console.error('Logout failed:', error)
    }
}
export const loginRedirect = async () => {
    try {
        await msalInstance.clearCache()
        await msalInstance.loginRedirect({
            scopes: ['User.Read'],
            prompt: 'select_account', // This forces the account selection screen
        })
    } catch (error) {
        console.error('Token acquisition failed:', error)
    }
}
