import React, { useContext, useState } from 'react'
import { CaretDownFilled, CaretRightFilled } from '@fluentui/react-icons'
import styles from './CoursesList.module.css'
import ChatHistoryList from '../ChatHistory/ChatHistoryList'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { Course } from '@/models'

const CoursesList: React.FC = () => {
    const sidebarContext = useContext(SidebarContext)
    const [expandedCourse, setExpandedCourse] = useState<string | null>(null)

    const handleSelectionChange = (courseName: string) => {
        sidebarContext.setSelectedCourse(courseName)
        setExpandedCourse(courseName)
        if (sidebarContext.selectedCourse !== courseName) {
            sidebarContext.setSelectedConversation(null)
        }
    }
    const handleCaretClick = (courseName: string) => {
        if (sidebarContext.selectedConversation === null) {
            console.log('selectedConversation is null')
        }
        // for debugging - remove later
        // else {
        //     console.log(`selectedConversation is not null ${sidebarContext.selectedConversation}`)
        // }
        if (expandedCourse === courseName) {
            setExpandedCourse(null)
        } else {
            setExpandedCourse(courseName)
        }
    }

    return (
        <div className={styles.coursesListContainer}>
            <ul style={{ padding: 0, margin: 0 }}>
                {sidebarContext.courses?.map((course: Course) => {
                    const isSelected = course.name === sidebarContext.selectedCourse

                    return (
                        <li key={course.name} style={{ listStyleType: 'none' }}>
                            <div
                                className={`${styles.coursesListItemContainer} ${isSelected ? styles.selected : ''}`}
                                aria-label={course.name}
                            >
                                <span
                                    className={styles.coursesListItemText}
                                    role="button"
                                    onClick={() => {
                                        handleSelectionChange(course.name)
                                    }}
                                >
                                    {course.name}{' '}
                                </span>
                                <div
                                    className={styles.caretIcon}
                                    role="button"
                                    onClick={() => {
                                        handleCaretClick(course.name)
                                    }}
                                    aria-label="expand course history"
                                >
                                    {expandedCourse === course.name ? <CaretDownFilled /> : <CaretRightFilled />}
                                </div>
                            </div>
                            {<ChatHistoryList course={course} expanded={expandedCourse === course.name} />}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default CoursesList
