import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import SenecaLogo from '../../assets/Seneca.png'
import { logout } from '@/auth/utils'
import { SidebarContext } from '../../contexts/SidebarContextProps'
import { UserContext } from '../../contexts/UserContext'
import {
    TextBulletListLtrFilled,
    ArrowExitFilled,
    WeatherMoonRegular,
    WeatherSunnyRegular,
} from '@fluentui/react-icons'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'

import ToolTipDialog from '@/components/Navbar/ToolTipDialog'

const Navbar = () => {
    const userContext = useContext(UserContext)
    const sidebarContext = useContext(SidebarContext)
    const [modeIsHovered, setModeIsHovered] = useState(false)

    const getThemeIcon = () => {
        if (userContext.theme === 'dark') {
            return modeIsHovered ? WeatherSunnyRegular : WeatherMoonRegular
        } else {
            return modeIsHovered ? WeatherMoonRegular : WeatherSunnyRegular
        }
    }

    const ThemeIcon = getThemeIcon()

    const handleLogoutClick = () => {
        logout()
    }

    const handleDarkModeClick = () => {
        if (userContext.theme === 'dark') {
            document.documentElement.setAttribute('data-theme', 'light')
            userContext.setTheme('light')
        } else {
            document.documentElement.setAttribute('data-theme', 'dark')
            userContext.setTheme('dark')
        }
        setModeIsHovered(false)
    }

    const handleHamburgerClick = () => {
        sidebarContext.setIsCollapsed(!sidebarContext.isCollapsed)
    }
    return (
        <header
            className="flex flex-row h-[4rem] text-primary-foreground items-center bg-primary gap-1 md:gap-3"
            role={'banner'}
        >
            <div className="flex flex-row gap-5 px-2">
                <Button
                    onClick={handleHamburgerClick}
                    variant="ghost"
                    size="icon"
                    className="hover:bg-black hover:text-primary-foreground hover:bg-opacity-20"
                >
                    <TextBulletListLtrFilled className="text-4xl" />
                </Button>
                <div className="flex justify-center items-center">
                    <img src={SenecaLogo} className="w-25 h-10" alt="seneca-logo" aria-hidden="true" />
                </div>
                <Separator className=" hidden md:inline ml-8 py-5 w-0.5" orientation="vertical" />
            </div>
            <div id="title-options" className="flex flex-row justify-between flex-grow overflow-auto">
                <div id="title" className="flex flex-row justify-center items-center gap-2">
                    <Link to="/">
                        <h3 className="hidden md:inline md:text-3xl">MyTutor</h3>
                    </Link>
                    <div className="hidden md:inline">
                        <ToolTipDialog />
                    </div>
                </div>
                <div className="flex gap-3 justify-center items-center px-2">
                    <div className="flex justify-center md:hidden">
                        <ToolTipDialog />
                    </div>
                    <ThemeIcon
                        className="cursor-pointer hover:bg-black hover:bg-opacity-20 rounded"
                        onClick={handleDarkModeClick}
                        onMouseEnter={() => setModeIsHovered(true)}
                        onMouseLeave={() => setModeIsHovered(false)}
                    />
                    <div className="hidden md:inline text-base">{userContext.userId}</div>
                    <TooltipProvider delayDuration={200}>
                        <Tooltip>
                            <TooltipTrigger
                                aria-label="logout"
                                onClick={handleLogoutClick}
                                className="flex justify-center items-center hover:bg-black hover:bg-opacity-20 rounded"
                            >
                                <ArrowExitFilled />
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Logout</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
        </header>
    )
}

export default Navbar
