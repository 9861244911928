import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogTrigger,
} from '@/components/ui/dialog'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import tips from './tips.json'
import { QuestionCircleRegular } from '@fluentui/react-icons'
import { Link } from 'react-router-dom'

export default function ToolTipDialog() {
    return (
        <Dialog>
            <TooltipProvider>
                <Tooltip defaultOpen>
                    <TooltipTrigger asChild className="flex justify-center items-center">
                        <DialogTrigger>
                            <QuestionCircleRegular className="animate-bounce-1/3" />
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent aria-label="usage-guidelines">
                        <p>Learn how to use MyTutor</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <DialogContent
                onCloseAutoFocus={(event) => {
                    event.preventDefault() // Prevents returning focus to the trigger element
                }}
            >
                <DialogHeader>
                    <DialogTitle>MyTutor: Effective Usage Guide</DialogTitle>
                </DialogHeader>
                <DialogDescription asChild>
                    <ul className="flex flex-col gap-3 py-2">
                        {tips.map((tip) => (
                            <li key={tip.title}>
                                <p>
                                    <strong>{tip.title}:</strong> {tip.description}
                                </p>
                            </li>
                        ))}
                    </ul>
                </DialogDescription>
                <DialogFooter className="text-sm font-light flex !justify-center">
                    Seneca Polytechnic Generative Artificial Intelligence&nbsp;
                    <Link
                        className="text-primary underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        to="https://www.senecapolytechnic.ca/about/policies/generative-ai-policy.html"
                    >
                        Usage Policy
                    </Link>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
